<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Books</h1>
        </div>
      </div>
    </div>
    <div class="main container">
      <div class="columns is-multiline">
        <div class="card">
          <div class="card-image">
            <figure class="image is-3by4">
              <img src="https://images-na.ssl-images-amazon.com/images/I/41DKRKDOqIL._SX320_BO1,204,203,200_.jpg" alt="Placeholder image" />
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">Polish Folk Songs and Dances</p>
                <p class="subtitle is-6">Dziewanowska, Ada</p>
                <p class="subtitle is-6">
                  Phone: (414) 225-9440<br>
                  Fax: (414) 225-9442<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Books",
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin-top: 30px;
}

.org-description {
  margin-top: 50px;
}

.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>